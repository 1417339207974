<template>
  <div id="create-appointment">
    <!-- {{ dateAppointment }} -->
    <!-- <b-form id="form-create-appointment"> -->
    <b-row>
      <b-col>
        <label>HN</label>
        <b-form-input
          v-model="hn"
          @keyup="checkEnter"
        />
      </b-col>
      <b-col>
        <label>Name</label>
        <b-form-input
          v-model="name"
          disabled
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label>Clinic</label>
        <v-select
          v-model="clinic"
          :options="clinicOptions"
          class="w-100"
          disabled
          :reduce="name => name.code"
          label="name"
          placeholder="select clinic"
        />
      </b-col>
      <b-col>
        <label>Doctor</label>
        <v-select
          v-model="doctor"
          :options="doctorOptions"
          class="w-100"
          disabled
          :reduce="name => name.code"
          label="name"
          placeholder="select doctor"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label for="startDate">Date</label>
        <b-form-datepicker
          id="dateAppointment"
          v-model="dateAppointment"
          :min="nextDay"
          :date-disabled-fn="dateDisabled"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          disabled
        />
      </b-col>
      <b-col>
        <label>Period</label>
        <v-select
          v-model="scheduleSelected"
          :options="scheduleLists"
          class="w-100"
          :reduce="name => name.id"
          label="name"
          placeholder="select schedule"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label>Service</label>
        <v-select
          v-model="service"
          :options="serviceOptions"
          class="w-100"
          :disabled="scheduleDate === ''"
          :reduce="name => name.code"
          label="name"
          placeholder="select service"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label for="startDate">Note</label>
        <b-form-textarea
          v-model="appointmentNote"
          size="md"
          placeholder="Type your details here .."
        />
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col
        cols="12"
        class="text-center"
      >
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="outline-secondary"
          class="mr-1"
          size="sm"
          @click="exitForm"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          size="sm"
          @click="createAppointment"
        >
          Make
        </b-button>
      </b-col>
    </b-row>
    <!-- </b-form> -->
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    clinic: {
      type: String,
      default: () => '',
    },
    doctor: {
      type: String,
      default: () => '',
    },
    clinicOptions: {
      type: Array,
      default: () => [],
    },
    doctorOptions: {
      type: Array,
      default: () => [],
    },
    doctorCodeId: {
      type: Number,
      default: () => 0,
    },
    baseSchedule: {
      type: Array,
      default: () => [],
    },
    serviceOptions: {
      type: Array,
      default: () => [],
    },
    dateAppointment: {
      type: String,
      default: () => '',
    },
    dayAppointment: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      curdate: new Date(),
      nextDay: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      scheduleSelected: '',
      scheduleLists: [],
      scheduleAllLists: [],
      hn: '',
      name: '',
      scheduleDate: '',
      service: '',
      appointmentNote: '',
      patientId: '0',
    }
  },
  created() {
    this.checkDateAppointment()
  },
  methods: {
    checkEnter(e) {
      // console.log(e)
      if (e.keyCode === 13) {
        this.getPatientIdAndName()
      }
    },
    exitForm() {
      this.$emit('exit', 'formCreateAppointmentModal')
    },
    checkDateAppointment() {
      if (this.dateAppointment !== '') {
        this.designSchedule()
      }
    },
    getPatientName() {
      // console.log('Get PT name')
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay()
      const day = date.getDate()
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13
    },
    getScheduleByDoctor() {
      // // console.log('Load Schedule')
      this.$router.push(`/nurse/schedule/${this.clinic}/${this.doctor}`).catch(() => {})
      this.currentMonth = new Date(this.$refs.scheduleCalendar.getApi().getDate()).getMonth() + 1
      this.currentYear = new Date(this.$refs.scheduleCalendar.getApi().getDate()).getFullYear()
      this.currentMonth = `0${this.currentMonth}`.substr(-2)
      this.doctorCodeID = this.getDoctorCodeIDByCode()
      if (this.doctorCodeID === '') {
        return
      }
      this.$http({
        url: '/api/DoctorSchedule/MonthlyDoctorSchedule',
        method: 'POST',
        data: {
          DoctorCodeID: this.doctorCodeID,
          Day: '',
          Month: this.currentMonth,
          Year: `${this.currentYear}`,
        },
      }).then(({ data }) => {
        this.fullScheduleInfo = data
        this.isScheduleMenuActive = true
        const scheduleList = []
        for (let i = 0; i < data.length; i += 1) {
          if (data[i].maxPatient > 0) {
            const dataTemp = data[i]
            const sumPatientAppointment = `${dataTemp.sumPatientAppointment}`
            const sumPatientRegistered = `${dataTemp.sumPatientRegistered}`
            const currentPatirent = this.$helpers.IntNull(sumPatientRegistered) + this.$helpers.IntNull(sumPatientAppointment)
            const maxPatient = `${dataTemp.maxPatient}`
            scheduleList.push({
              id: i,
              url: '',
              title: `${currentPatirent} / ${maxPatient}`,
              start: data[i].date,
              end: data[i].date,
              allDay: true,
            })
          }
        }
        this.calendarOptions.events = scheduleList
        // console.log(scheduleList)
        this.getBaseSchedule()
      })
    },
    designSchedule() {
      const tempSchedule = []
      for (let i = 0; i < this.baseSchedule.length; i += 1) {
        const scheduleDetails = this.baseSchedule[i]
        for (let s = 0; s < scheduleDetails.data.length; s += 1) {
          this.scheduleAllLists.push(scheduleDetails.data[s])
          if (scheduleDetails.data[s].day.includes(this.dayAppointment)) {
            tempSchedule.push(
              {
                id: scheduleDetails.data[s].scheduleID,
                name: `${scheduleDetails.data[s].day} ${scheduleDetails.data[s].startTime.substr(0, 5)} - ${scheduleDetails.data[s].endTime.substr(0, 5)}`,
                numOfPatient: scheduleDetails.data[s].numOfPatient,
                useDate: scheduleDetails.data[s].startUse.substr(0, 10),
              },
            )
          }
        }
      }
      this.scheduleLists = tempSchedule
    },
    showScheduleInfo() {
      // console.log(this.scheduleSelected)
      for (let i = 0; i < this.scheduleAllLists.length; i += 1) {
        if (this.scheduleSelected === this.scheduleAllLists[i].scheduleID) {
          this.daySelected = this.scheduleAllLists[i].day
          this.startDate = this.scheduleAllLists[i].startUse.substr(0, 10)
          this.endDate = this.scheduleAllLists[i].endUse.substr(0, 10)
          this.startTime = this.scheduleAllLists[i].startTime.substr(0, 5)
          this.endTime = this.scheduleAllLists[i].endTime.substr(0, 5)
          this.amountOfPatient = this.scheduleAllLists[i].numOfPatient
        }
      }
    },
    getPatientIdAndName() {
      this.hn = `0000000${this.hn}`.substr(-7)
      this.$http({
        url: '/api/Patient/PatientDetailByFilter',
        method: 'POST',
        data: {
          HN: this.hn,
          IDCard: '',
          PatientID: 0,
        },
      }).then(({ data }) => {
        // console.log(data)
        this.name = data.patientName
        this.patientId = data.patientID
      })
    },
    createAppointment(e) {
      e.preventDefault()
      // if (this.daySelected.length === 0) {
      //   return
      // }
      // if (this.amountOfPatient <= 0) {
      //   return
      // }

      this.$http({
        url: '/api/Appointment/NewAppointment',
        method: 'POST',
        data: {
          appointment: {
            ScheduleID: this.scheduleSelected,
            PatientID: 0,
            AppointmentDate: this.dateAppointment,
            AppointmentNote: `${this.AppointmentNote}`,
            ComeForCode: '',
          },
          HN: this.hn,
          DoctorCodeID: this.doctorCodeId,
        },
      }).then(() => {
        // console.log(data)
        this.$swal({
          title: 'Appointment Created.',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$emit('exit', 'formCreateAppointmentModal')
          }
        })
      })
    },
  },
}
</script>
